import React from "react";

export const About = () => {
  return (
    <div
      className={`bg-white  text-[16px] sm:text-[18px] text-justify w-full h-full flex justify-center items-center rounded-lg overflow-y-scroll no-scrollbar`}
    >
      <div
        className={`text-justify p-4 flex flex-col overflow-auto ${
          window.innerWidth > 900 ? "max-w-[80%]" : ""
        }`}
        style={{ maxHeight: "100%" }}
      >
        <div
          style={{
            fontFamily: "'Rubik', sans-serif",
            fontWeight: "20",
            lineHeight: "2em",
            color: "black",
            letterSpacing: -0.5,
            whiteSpace: "normal",
          }}
        >
          Reealtech is a vibrant hub of innovation, driven by our passion for
          reshaping the real estate and hospitality industries through
          innovative technology solutions. Founded by childhood friends Saurabh
          and Siddharth, Reealtech is fueled by a passion for innovation and a
          drive to revolutionize how properties are viewed and booked. Our
          team's expertise in Extended Reality (XR), and Software Development
          enables us to create immersive and seamless experiences for our
          clients and their customers. From virtual property tours to
          personalized hotel experiences, we are redefining how people interact
          with physical spaces. We believe in the transformative power of
          technology to inspire awe and delight. Together, we can create a more
          connected, immersive, and engaging world for all.
        </div>
        <div className="mt-8 text-[20px] sm:text-[24px]">
          <p
            style={{
              fontFamily: "'Antonio', sans-serif",
              flex: "1 1 0%",
              fontWeight: "50",
              lineHeight: "1em",
              textAlign: "center",
              color: "black",
              letterSpacing: -0.5,
              whiteSpace: "nowrap",
            }}
          >
            Why Choose Reealtech
          </p>
        </div>
        <div
          className="p-4 mb-[-20px]"
          style={{
            fontFamily: "'Rubik', sans-serif",
            fontWeight: "20",
            lineHeight: "2em",
            color: "black",
            letterSpacing: -0.5,
            whiteSpace: "normal",
          }}
        >
          <ul className="list-disc">
            <li>
              <span className="font-bold">Innovation:</span> We are at the
              forefront of innovation, pioneering India's first 360 VR Tour
              Editor and developing cutting-edge solutions for the real estate
              and hospitality industries.
            </li>
            <li>
              <span className="font-bold">Expertise:</span> Our team consists of
              experienced professionals in XR, ML, Cloud technologies, and
              full-stack web development, ensuring that we deliver high-quality
              solutions tailored to our clients' needs.
            </li>
            <li>
              <span className="font-bold">Customer-Centric Approach:</span> We
              prioritize customer satisfaction and strive to provide the best
              possible experience for both consumers and businesses in the real
              estate and hospitality industries.
            </li>
            <li>
              <span className="font-bold">Partnerships:</span> We collaborate
              with industry leaders and experts to ensure that our solutions are
              in line with the latest trends and technologies, keeping our
              clients ahead of the curve.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
