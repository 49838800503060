import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Hero } from "./components/Hero";
import { Underlay } from "./components/Underlay";
import { Loading } from "./components/Loading";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isLoading) {
        setIsLoading(window.innerWidth < 600 && window.scrollY === 0);
      }
    };
  
    handleResize();
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoading]); 

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Suspense>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Underlay />
                </>
              }
            />
          </Routes>
        </Suspense>
      )}
    </>
  );
}

export default App;
